"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TemplateBanner = (function () {
    function TemplateBanner(createUi, isTemplateDomain, clientUrl, projectName, secretHash) {
        this.ui = createUi(require("./TemplateBanner.html"), {
            isTemplateDomain: isTemplateDomain,
            projectName: projectName,
            useLink: "".concat(clientUrl, "/#/signup?with_template=").concat(secretHash, "&entry_point=template"),
        });
    }
    return TemplateBanner;
}());
exports.default = TemplateBanner;
