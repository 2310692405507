"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlocks = exports.partialCloneNode = exports.HTMLTag = exports.NodeType = exports.isRootNode = void 0;
var Layout_css_1 = require("@CommonFrontend/Public/Widgets/Layout/Layout.css");
var BlockStyles_css_1 = require("@CommonFrontend/Styles/BlockStyles/BlockStyles.css");
var SelectorUtils_1 = require("@CommonFrontend/Utils/SelectorUtils");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var lodash_1 = require("lodash");
var isRootNode = function (node) { return !node.parent; };
exports.isRootNode = isRootNode;
var NodeType;
(function (NodeType) {
    NodeType[NodeType["Element"] = 1] = "Element";
    NodeType[NodeType["Text"] = 3] = "Text";
})(NodeType || (exports.NodeType = NodeType = {}));
var HTMLTag;
(function (HTMLTag) {
    HTMLTag["H1"] = "H1";
    HTMLTag["H2"] = "H2";
    HTMLTag["P"] = "P";
    HTMLTag["LI"] = "LI";
    HTMLTag["BR"] = "BR";
    HTMLTag["FIGURE"] = "FIGURE";
})(HTMLTag || (exports.HTMLTag = HTMLTag = {}));
var PAGE_PADDING = 40 * 2;
var MIN_LENGTHS_BEFORE_SPLIT = [
    { tagName: HTMLTag.H1, minLength: 50 },
    { tagName: HTMLTag.H2, minLength: 70 },
    { tagName: HTMLTag.P, minLength: 120 },
    { tagName: HTMLTag.LI, minLength: 120 },
];
var MIN_CHARS_FOLLOWING_HEADING_BEFORE_SPLIT = 600;
var HEADING_TAGS = [HTMLTag.H1, HTMLTag.H2];
var isPrintableNode = function (child) {
    return child.nodeType === NodeType.Element ||
        (child.nodeType === NodeType.Text && !child.textContent.match(/^\s*$/));
};
var partialCloneNode = function (node, path) {
    var _a = __read(path), childIndex = _a[0], childPath = _a.slice(1);
    var children = node.children.map(function (branch, branchIndex) {
        return childIndex && branchIndex === childIndex[0]
            ? branch.slice(childIndex[1]).map(function (child, idx) { return (0, exports.partialCloneNode)(child, idx === 0 ? childPath : []); })
            : branch.map(function (child) { return (0, exports.partialCloneNode)(child, []); });
    });
    if ((0, exports.isRootNode)(node)) {
        return twoWayLink.apply(void 0, __spreadArray([{
                element: node.element,
                foregroundColor: node.foregroundColor,
                backgroundColor: node.backgroundColor,
                split: childIndex !== undefined,
            }], __read(children), false));
    }
    return twoWayLink.apply(void 0, __spreadArray([{ element: node.element, orphanable: node.orphanable, split: path.length > 0 }], __read(children), false));
};
exports.partialCloneNode = partialCloneNode;
var splitAt = function (element, selector) {
    var root = element.cloneNode(true);
    var slotInsertionPoint = selector ? root.querySelector(selector) : root;
    if (!slotInsertionPoint) {
        throw new Error("Unable to split ".concat(element, " at ").concat(selector));
    }
    var slot = document.createElement("slot");
    slotInsertionPoint.innerHTML = "";
    slotInsertionPoint.append(slot);
    return [
        root,
        Array.from(selector ? element.querySelector(selector).childNodes : element.childNodes)
            .filter(isPrintableNode)
            .map(function (child) { return child.cloneNode(true); }),
    ];
};
var splitAfter = function (element, selector) {
    var e_1, _a;
    var root = element.cloneNode(true);
    var slotInsertionPoint = root.querySelector(selector);
    if (!slotInsertionPoint) {
        throw new Error("Unable to split ".concat(element, " at ").concat(selector));
    }
    var slot = document.createElement("slot");
    var slotInsertionPointParent = slotInsertionPoint.parentElement;
    var slotInsertionPointSiblings = Array.from(slotInsertionPointParent.children);
    slotInsertionPointParent.innerHTML = "";
    var removedChildren = [];
    var beforeInsertionPoint = true;
    try {
        for (var slotInsertionPointSiblings_1 = __values(slotInsertionPointSiblings), slotInsertionPointSiblings_1_1 = slotInsertionPointSiblings_1.next(); !slotInsertionPointSiblings_1_1.done; slotInsertionPointSiblings_1_1 = slotInsertionPointSiblings_1.next()) {
            var sibling = slotInsertionPointSiblings_1_1.value;
            if (beforeInsertionPoint) {
                slotInsertionPointParent.append(sibling);
                if (sibling === slotInsertionPoint) {
                    slotInsertionPointParent.append(slot);
                    beforeInsertionPoint = false;
                }
            }
            else {
                removedChildren.push(sibling.cloneNode(true));
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (slotInsertionPointSiblings_1_1 && !slotInsertionPointSiblings_1_1.done && (_a = slotInsertionPointSiblings_1.return)) _a.call(slotInsertionPointSiblings_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return [root, removedChildren];
};
var getColors = function (element) {
    var foregroundColor;
    var $el = $(element);
    var primaryColorNodes = $el.find(HTMLTag.P);
    var secondaryColorNodes = $el.find("".concat(HTMLTag.H1, ",").concat(HTMLTag.H2));
    if (primaryColorNodes.length) {
        foregroundColor = primaryColorNodes.css("color");
    }
    else if (secondaryColorNodes.length) {
        foregroundColor = secondaryColorNodes.css("color");
    }
    else {
        var $tempNode = $("<".concat(HTMLTag.P, "/>"));
        $el.append($tempNode);
        foregroundColor = $tempNode.css("color");
        $tempNode.remove();
    }
    var backgroundColor = $el.css("background-color");
    return { foregroundColor: foregroundColor, backgroundColor: backgroundColor };
};
var lastChildIsOrphanable = function (children) {
    return children.length > 0 && !!children[children.length - 1].orphanable;
};
var twoWayLink = function (parent) {
    var childrenArrays = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        childrenArrays[_i - 1] = arguments[_i];
    }
    parent.children = childrenArrays;
    childrenArrays.forEach(function (childrenArray) { return childrenArray.forEach(function (child) { return (child.parent = parent); }); });
    return parent;
};
var mapEditorNodes = function (elements, options) {
    var mapEditorNodesRecursively = function (elements, textOptions) {
        return elements.reduce(function (acc, element, elementIndex) {
            var _a;
            var isTextNode = element.nodeType === NodeType.Text;
            if (isTextNode) {
                var textContent = element.textContent;
                var words_1 = textContent.match(/(?:\s+)?\S+(?:\s+)?/g);
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), __read(words_1.map(function (word, idx) { return ({
                        element: document.createTextNode(word),
                        children: [],
                        orphanable: textOptions.characterOffset +
                            acc.characterCount +
                            word.length +
                            words_1.slice(0, idx).reduce(function (total, w) { return total + w.length; }, 0) <
                            (textOptions.minLength || 0),
                    }); })), false),
                    characterCount: acc.characterCount + textContent.length,
                };
            }
            var isHeading = HEADING_TAGS.includes(HTMLTag[element.tagName]);
            var isImage = element.tagName === HTMLTag.FIGURE;
            var isVideo = element.classList.contains("video-content");
            var isROICalculator = element.classList.contains("roi-calculator");
            var isEmbeddedContent = element.classList.contains("embedded-content");
            var isOrderedListItem = element.classList.contains("ordered");
            var isTwoColumn = element.classList.contains("side-by-side__wrapper");
            var isLayout = element.classList.contains(Layout_css_1.layout);
            var isAccordion = (_a = element.firstElementChild) === null || _a === void 0 ? void 0 : _a.classList.contains("accordion-widget__content");
            var isTable = element.classList.contains("qwilr-table-scroller");
            var isLineBreak = element.tagName === HTMLTag.BR;
            var isEmptyParagraph = element.tagName === HTMLTag.P && element.textContent === "";
            var isQuoteSummary = element.getAttribute("data-hydration-component") === "QuoteSummaryWidget";
            var isLastElement = elementIndex === elements.length - 1;
            var nextElement = !isLastElement ? elements[elementIndex + 1] : undefined;
            var isNextElementNonNestedHeading = nextElement &&
                HEADING_TAGS.includes(HTMLTag[nextElement.tagName]) &&
                !(nextElement.tagName === HTMLTag.H2 && !!acc.previousHeading && acc.previousHeading.type === HTMLTag.H1);
            var headingBufferEnd = acc.previousHeading
                ? acc.previousHeading.endIndex + MIN_CHARS_FOLLOWING_HEADING_BEFORE_SPLIT
                : NaN;
            var isInsideHeadingBuffer = !isHeading && !!acc.previousHeading && headingBufferEnd > acc.characterCount;
            var remainingCharactersFollowingHeading = isInsideHeadingBuffer ? headingBufferEnd - acc.characterCount : 0;
            if ((isVideo && options.removeVideos) || (isEmbeddedContent && options.removeEmbeddedContent)) {
                return acc;
            }
            if (isLineBreak) {
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        {
                            element: element,
                            children: [],
                            orphanable: textOptions.minLength !== undefined && acc.characterCount < textOptions.minLength,
                        },
                    ], false),
                    characterCount: acc.characterCount,
                };
            }
            if (isImage || isVideo || isEmbeddedContent || isROICalculator) {
                if (isImage) {
                    var maxHeight = options.pageHeight - PAGE_PADDING;
                    $(element).find("img").css({ "max-height": maxHeight, "object-fit": "contain" });
                }
                if (isROICalculator) {
                    $(element).find(".roi-calculator-button").remove();
                    $(element).find(".roi-calculator-typography__result").css("font-size", "46px");
                    $(element).find(".roi-calculator-typography__result-affix").css("font-size", "23px");
                }
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        {
                            element: element,
                            children: [],
                            orphanable: isInsideHeadingBuffer && !isNextElementNonNestedHeading,
                        },
                    ], false),
                    characterCount: acc.characterCount,
                    previousHeading: acc.previousHeading,
                };
            }
            if (isTwoColumn) {
                var cleanColumn = function (column) {
                    var removeContent = function (querySelector) {
                        var contentElements = column.querySelectorAll(querySelector);
                        contentElements.forEach(function (contentElement) {
                            var _a;
                            (_a = contentElement.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(contentElement);
                        });
                        return contentElements.length > 0;
                    };
                    var removedVideos = options.removeVideos && removeContent(".video-content");
                    var removedEmbed = options.removeEmbeddedContent && removeContent(".embedded-content");
                    var isColumnEmpty = column.childElementCount === 0;
                    return isColumnEmpty && (removedVideos || removedEmbed);
                };
                var reflowColumn = function (column) {
                    var childNodes = Array.from(column.childNodes).filter(isPrintableNode);
                    var contentNodes = mapEditorNodesRecursively(childNodes, {
                        characterOffset: acc.characterCount + textOptions.characterOffset,
                        minLength: textOptions.minLength,
                    });
                    return {
                        nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), __read(contentNodes.nodes), false),
                        characterCount: acc.characterCount + contentNodes.characterCount,
                        previousHeading: contentNodes.previousHeading,
                    };
                };
                var twoColumnElement = element.cloneNode(true);
                var leftColumn = twoColumnElement.querySelector(".side-by-side__col--left");
                var rightColumn = twoColumnElement.querySelector(".side-by-side__col--right");
                var wasLeftColumnEmptied = cleanColumn(leftColumn);
                var wasRightColumnEmptied = cleanColumn(rightColumn);
                if (wasLeftColumnEmptied && wasRightColumnEmptied) {
                    return acc;
                }
                else if (wasLeftColumnEmptied) {
                    return reflowColumn(rightColumn);
                }
                else if (wasRightColumnEmptied) {
                    return reflowColumn(leftColumn);
                }
                else {
                    var _b = __read(splitAt(element, ".side-by-side__col--left"), 2), twoCol = _b[0], leftCol = _b[1];
                    var _c = __read(splitAt(twoCol, ".side-by-side__col--right"), 2), twoCol2 = _c[0], rightCol = _c[1];
                    var mappedLeftCol = mapEditorNodesRecursively(leftCol, {
                        characterOffset: acc.characterCount + textOptions.characterOffset,
                        minLength: textOptions.minLength,
                    });
                    var mappedRightCol = mapEditorNodesRecursively(rightCol, {
                        characterOffset: acc.characterCount + textOptions.characterOffset,
                        minLength: textOptions.minLength,
                    });
                    return {
                        nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [twoWayLink({ element: twoCol2 }, mappedLeftCol.nodes, mappedRightCol.nodes)], false),
                        characterCount: acc.characterCount + Math.max(mappedLeftCol.characterCount, mappedRightCol.characterCount),
                    };
                }
            }
            if (isLayout) {
                var layoutWrapper = element;
                var columns = [];
                for (var columnIdx = 0; columnIdx < layoutWrapper.children.length; columnIdx++) {
                    var _d = __read(splitAt(layoutWrapper, "*:nth-child(".concat(columnIdx + 1, ")")), 2), newLayoutWrapper = _d[0], column = _d[1];
                    layoutWrapper = newLayoutWrapper;
                    columns.push(mapEditorNodesRecursively(column, {
                        characterOffset: acc.characterCount + textOptions.characterOffset,
                        minLength: textOptions.minLength,
                    }));
                }
                var characterCount = Math.max.apply(Math, __spreadArray([], __read(columns.map(function (column) { return column.characterCount; })), false));
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        twoWayLink.apply(void 0, __spreadArray([{
                                element: layoutWrapper,
                            }], __read(columns.map(function (column) { return column.nodes; })), false)),
                    ], false),
                    characterCount: acc.characterCount + characterCount,
                };
            }
            if (isAccordion) {
                var _e = __read(splitAt(element, ".accordion-widget__content"), 2), accordionWrapper = _e[0], _f = __read(_e[1], 2), accordionHeading = _f[0], accordionBody = _f[1];
                var _g = __read(splitAt(accordionBody, ".accordion-widget-body"), 2), accordionBodyWrapper = _g[0], accordionBodyContent = _g[1];
                accordionBodyWrapper.removeAttribute("style");
                var mappedAccordionBody = mapEditorNodesRecursively(accordionBodyContent, {
                    characterOffset: acc.characterCount + textOptions.characterOffset,
                    minLength: textOptions.minLength,
                });
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        twoWayLink({ element: accordionWrapper }, [
                            { element: accordionHeading, orphanable: true, children: [] },
                            twoWayLink({ element: accordionBodyWrapper }, mappedAccordionBody.nodes),
                        ]),
                    ], false),
                    characterCount: acc.characterCount + mappedAccordionBody.characterCount,
                };
            }
            if (isTable) {
                var _h = __read(splitAt(element, "tbody"), 2), tableWrapper = _h[0], tableRows = _h[1];
                var tableCharCount_1 = 0;
                var mappedTableRows = tableRows.map(function (row) {
                    var rowWrapper = row;
                    var cellContentsForRow = [];
                    for (var i = 0; i < row.children.length; i++) {
                        var _a = __read(splitAt(rowWrapper, "td:nth-child(".concat(i + 1, ")")), 2), newRowWrapper = _a[0], cellContents = _a[1];
                        cellContentsForRow.push(cellContents);
                        rowWrapper = newRowWrapper;
                    }
                    return twoWayLink.apply(void 0, __spreadArray([{ element: rowWrapper }], __read(cellContentsForRow.map(function (cell) {
                        var mappedCellContents = mapEditorNodesRecursively(cell, {
                            characterOffset: acc.characterCount + textOptions.characterOffset,
                            minLength: textOptions.minLength,
                        });
                        tableCharCount_1 += mappedCellContents.characterCount;
                        return mappedCellContents.nodes;
                    })), false));
                });
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        twoWayLink({ element: tableWrapper }, mappedTableRows.map(function (r) { return ({
                            element: r.element,
                            orphanable: r.element.classList.contains("table-header-row"),
                            children: r.children,
                        }); })),
                    ], false),
                    characterCount: acc.characterCount + tableCharCount_1,
                };
            }
            if (isQuoteSummary) {
                var isWidgetPlaceholder = document.getElementsByClassName("summary-wrapper")[0].getAttribute("data-is-summary-placeholder") ===
                    "true";
                if (isWidgetPlaceholder) {
                    return {
                        nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [twoWayLink({ element: element }, [])], false),
                        characterCount: acc.characterCount,
                    };
                }
                var _j = __read(splitAt(element, ".summary-wrapper"), 2), summaryWrapper = _j[0], _k = __read(_j[1], 2), quoteBlocksList = _k[0], grandTotal = _k[1];
                var _l = __read(splitAt(quoteBlocksList), 2), quoteBlockListWrapper = _l[0], quotes = _l[1];
                return {
                    nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                        twoWayLink({ element: summaryWrapper }, [
                            twoWayLink({ element: quoteBlockListWrapper }, __spreadArray([], __read(quotes.map(function (quoteBlock) {
                                var _a = __read(splitAt(quoteBlock), 2), quoteWrapper = _a[0], quoteChildren = _a[1];
                                return twoWayLink({ element: quoteWrapper }, quoteChildren.map(function (quoteChild) {
                                    if (quoteChild.classList.contains("quote-heading")) {
                                        return { element: quoteChild, children: [], orphanable: true };
                                    }
                                    if (quoteChild.classList.contains("quote-section-list")) {
                                        var _a = __read(splitAt(quoteChild), 2), sectionListWrapper = _a[0], sections = _a[1];
                                        return twoWayLink({ element: sectionListWrapper }, sections.map(function (section) {
                                            var _a = __read(splitAt(section), 2), sectionWrapper = _a[0], sectionChildren = _a[1];
                                            return twoWayLink({ element: sectionWrapper }, sectionChildren.map(function (sectionChild) {
                                                if (sectionChild.classList.contains("section-description")) {
                                                    return { element: sectionChild, children: [], orphanable: true };
                                                }
                                                if (sectionChild.classList.contains("section-table")) {
                                                    var _a = __read(splitAt(sectionChild, "tbody"), 2), tableWrapper = _a[0], tableRows = _a[1];
                                                    return twoWayLink({ element: tableWrapper, orphanable: true }, tableRows.map(function (row) {
                                                        var hasRowChildren = row.getElementsByTagName("div").length > 0;
                                                        if (!hasRowChildren) {
                                                            return { element: row, children: [] };
                                                        }
                                                        var _a = __read(splitAt(row, "td > div"), 2), rowWrapper = _a[0], rowDescriptionNodes = _a[1];
                                                        return twoWayLink({ element: rowWrapper }, mapEditorNodes(rowDescriptionNodes, options));
                                                    }));
                                                }
                                                if (sectionChild.classList.contains("section-footer")) {
                                                    return { element: sectionChild, children: [] };
                                                }
                                            }));
                                        }));
                                    }
                                    if (quoteChild.classList.contains("quote-total")) {
                                        var _b = __read(splitAt(quoteChild, "tfoot"), 2), quoteTotalWrapper = _b[0], quoteTotalChildren = _b[1];
                                        return twoWayLink({ element: quoteTotalWrapper }, quoteTotalChildren.map(function (row) { return ({
                                            element: row,
                                            orphanable: true,
                                            children: [],
                                        }); }));
                                    }
                                }));
                            })), false)),
                            { element: grandTotal, children: [] },
                        ]),
                    ], false),
                    characterCount: acc.characterCount,
                };
            }
            var _m = __read(isOrderedListItem ? splitAfter(element, ".before") : splitAt(element), 2), node = _m[0], children = _m[1];
            var minLengthSetting = MIN_LENGTHS_BEFORE_SPLIT.find(function (l) { return l.tagName === element.tagName; });
            var minLength;
            if (isInsideHeadingBuffer && minLengthSetting) {
                minLength = Math.max(remainingCharactersFollowingHeading, minLengthSetting.minLength);
            }
            else if (isInsideHeadingBuffer) {
                minLength = remainingCharactersFollowingHeading;
            }
            else if (minLengthSetting) {
                minLength = minLengthSetting.minLength;
            }
            else {
                minLength = textOptions.minLength;
            }
            var childNodes = mapEditorNodesRecursively(children, {
                characterOffset: minLengthSetting ? 0 : textOptions.characterOffset + acc.characterCount,
                minLength: minLength,
            });
            var childNodesEnd = acc.characterCount + childNodes.characterCount;
            var isNestedOrphanableElement = !minLengthSetting && lastChildIsOrphanable(childNodes.nodes);
            var isUnderCharactersFollowingHeadingThreshold = isInsideHeadingBuffer && headingBufferEnd > childNodesEnd;
            if (isEmptyParagraph && isLastElement) {
                return acc;
            }
            return {
                nodes: __spreadArray(__spreadArray([], __read(acc.nodes), false), [
                    twoWayLink({
                        element: node,
                        orphanable: isHeading ||
                            isNestedOrphanableElement ||
                            (isUnderCharactersFollowingHeadingThreshold && !isNextElementNonNestedHeading) ||
                            (isEmptyParagraph && !isNextElementNonNestedHeading),
                    }, childNodes.nodes),
                ], false),
                characterCount: childNodesEnd,
                previousHeading: isHeading
                    ?
                        { type: HTMLTag[element.tagName], endIndex: childNodesEnd }
                    : acc.previousHeading,
            };
        }, { nodes: [], characterCount: 0 });
    };
    return mapEditorNodesRecursively(elements, { characterOffset: 0 }).nodes;
};
var GenericBlock = {
    isBlockType: function (_element) { return true; },
    make: function (element) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            return __assign({ element: element.cloneNode(true), children: [] }, getColors(element));
        },
    }); },
};
var SplashBlock = {
    isBlockType: function (element) { return element.classList.contains("splash-module"); },
    make: function (element, options) {
        var fullPage = $(element).outerHeight() >= options.pageHeight;
        return {
            blockId: element.getAttribute("block-id"),
            type: element.getAttribute("data-type"),
            get tree() {
                var isEmptySplash = !element.querySelector(".content");
                var _a = __read(!isEmptySplash
                    ? splitAt(element, ".content")
                    : [element.cloneNode(true), []], 2), wrapper = _a[0], children = _a[1];
                return twoWayLink(__assign({ element: wrapper }, getColors(element)), mapEditorNodes(children, options));
            },
            startNewPageBefore: fullPage,
            startNewPageAfter: fullPage,
        };
    },
};
var TextBlock = {
    isBlockType: function (element) { return element.classList.contains("text-block"); },
    make: function (element, options) {
        var fullPage = element.style.getPropertyValue((0, SelectorUtils_1.toVar)(BlockStyles_css_1.blockStyleVars.minBlockHeight)) === "100vh";
        return {
            blockId: element.getAttribute("block-id"),
            type: element.getAttribute("data-type"),
            get tree() {
                var _a = __read(splitAt(element, ".columns"), 2), wrapper = _a[0], children = _a[1];
                return twoWayLink(__assign({ element: wrapper }, getColors(element)), mapEditorNodes(children, options));
            },
            startNewPageBefore: fullPage,
            startNewPageAfter: fullPage,
        };
    },
};
var QuoteBlock = {
    isBlockType: function (element) {
        return element.classList.contains("quote-v2-module") && !element.querySelector(".qwilr-quote-v2-react");
    },
    make: function (element, options) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            var wrapper = element.cloneNode(true);
            wrapper.innerHTML = "";
            $(wrapper).append($('<div class="qwilr-quote-v2"><div class="quote-content-wrapper"><slot></slot></div></div>'));
            var _a = __read(splitAt(element.querySelector(".quote-header")), 2), headerWrapperElement = _a[0], headerChildrenElements = _a[1];
            var _b = __read(splitAt(element.querySelector(".qwilr-quote-v2")), 2), tableChildrenElements = _b[1];
            var hasTotal = tableChildrenElements[tableChildrenElements.length - 1].classList.contains("quote-total");
            var headerContentNodes = mapEditorNodes(headerChildrenElements, options);
            var headerOrphanable = headerContentNodes.length === 0 || headerContentNodes[headerContentNodes.length - 1].orphanable;
            return twoWayLink(__assign({ element: wrapper }, getColors(element)), __spreadArray(__spreadArray([
                twoWayLink({ element: headerWrapperElement, orphanable: headerOrphanable }, headerContentNodes)
            ], __read(tableChildrenElements.slice(0, hasTotal ? tableChildrenElements.length - 1 : undefined).map(function (child) {
                var _a = __read(splitAt(child, ".quote-section"), 2), tableWrapper = _a[0], tableElements = _a[1];
                var _b = __read(tableElements, 2), tableHeader = _b[0], tableBody = _b[1];
                if (!tableBody) {
                    return twoWayLink({ element: tableWrapper }, [{ element: tableHeader, children: [] }]);
                }
                var _c = __read(splitAfter(tableBody, ".quote-items-header"), 2), tableBodyWrapper = _c[0], tableRows = _c[1];
                tableHeader.querySelector(".collapse-line-items").remove();
                return twoWayLink({ element: tableWrapper }, [
                    { element: tableHeader, children: [], orphanable: true },
                    twoWayLink({ element: tableBodyWrapper }, tableRows.map(function (row) { return ({ element: row, children: [] }); })),
                ]);
            })), false), __read((hasTotal ? [{ element: tableChildrenElements[tableChildrenElements.length - 1], children: [] }] : [])), false));
        },
    }); },
};
var ReactQuoteBlock = {
    isBlockType: function (element) {
        return element.classList.contains("quote-v2-module") && !!element.querySelector(".qwilr-quote-v2-react");
    },
    make: function (element, options) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            var _a = __read(splitAt(element, ".row"), 2), wrapper = _a[0], _b = __read(_a[1], 2), header = _b[0], quote = _b[1];
            var _c = __read(splitAt(header), 2), headerWrapper = _c[0], headerChildren = _c[1];
            var headerContentNodes = mapEditorNodes(headerChildren, options);
            var headerOrphanable = lastChildIsOrphanable(headerContentNodes);
            var _d = __read(splitAt(quote, ".qwilr-quote-v2-react"), 2), quoteWrapper = _d[0], quoteElements = _d[1];
            return twoWayLink(__assign({ element: wrapper }, getColors(element)), [
                twoWayLink({ element: headerWrapper, orphanable: headerOrphanable }, headerContentNodes),
                twoWayLink({ element: quoteWrapper }, quoteElements.map(function (quoteElement) {
                    var isQuoteTotal = quoteElement.classList.contains("quote-total");
                    if (isQuoteTotal) {
                        return { element: quoteElement, children: [] };
                    }
                    var isPlanCardSection = quoteElement.classList.contains("plan-section");
                    if (isPlanCardSection) {
                        var _a = __read(splitAt(quoteElement), 2), sectionWrapper_1 = _a[0], _b = __read(_a[1], 3), sectionHeader_1 = _b[0], frequencyTabs = _b[1], sectionBody_1 = _b[2];
                        var hasFrequencyTabs = !!sectionBody_1;
                        if (!hasFrequencyTabs) {
                            sectionBody_1 = frequencyTabs;
                        }
                        var sectionDescription = sectionHeader_1;
                        var _c = __read(splitAt(sectionDescription), 2), sectionDescriptionWrapper = _c[0], _d = __read(_c[1], 2), sectionDetailsInner = _d[0], sectionDescriptionInner = _d[1];
                        var hasSectionDetails = !!sectionDescriptionInner;
                        if (!hasSectionDetails) {
                            sectionDescriptionInner = sectionDetailsInner;
                        }
                        var _e = __read(splitAt(sectionDescriptionInner), 2), sectionDescriptionContentWrapper = _e[0], sectionDescriptionContent = _e[1];
                        var sectionDescriptionContentNodes = mapEditorNodes(sectionDescriptionContent, options);
                        var _f = __read(splitAt(sectionBody_1), 2), planCardWrapper_1 = _f[0], planCards_1 = _f[1];
                        var planCardRows = (0, lodash_1.range)(0, Math.ceil(planCards_1.length / 3));
                        return twoWayLink({ element: sectionWrapper_1 }, __spreadArray(__spreadArray([
                            twoWayLink({
                                element: sectionDescriptionWrapper,
                                orphanable: lastChildIsOrphanable(sectionDescriptionContentNodes),
                            }, __spreadArray(__spreadArray([], __read((hasSectionDetails ? [{ element: sectionDetailsInner, children: [], orphanable: true }] : [])), false), [
                                twoWayLink({ element: sectionDescriptionContentWrapper }, sectionDescriptionContentNodes),
                            ], false))
                        ], __read((hasFrequencyTabs ? [{ element: frequencyTabs, children: [], orphanable: true }] : [])), false), __read(planCardRows.map(function (rowIndex) {
                            var rowCards = (0, lodash_1.take)((0, lodash_1.drop)(planCards_1, rowIndex * 3), 3);
                            var rowCardWrapper = planCardWrapper_1.cloneNode(true);
                            var slotParent = rowCardWrapper.querySelector("slot").parentElement;
                            rowCardWrapper.querySelector("slot").remove();
                            for (var i = 0; i < rowCards.length; i++) {
                                var cardWrapper = document.createElement("div");
                                var cardSlot = document.createElement("slot");
                                cardWrapper.append(cardSlot);
                                slotParent.append(cardWrapper);
                            }
                            return twoWayLink.apply(void 0, __spreadArray([{ element: rowCardWrapper }], __read(rowCards.map(function (card) {
                                var _a = __read(splitAt(card, ".plan-card__card-element-container"), 2), cardWrapper = _a[0], _b = __read(_a[1], 2), cardElement = _b[0], maybeFeatureList = _b[1];
                                if (maybeFeatureList) {
                                    var _c = __read(splitAt(maybeFeatureList, ".section__description-text"), 2), featureListWrapper = _c[0], editorNodes = _c[1];
                                    return [
                                        twoWayLink({
                                            element: cardWrapper,
                                        }, [
                                            { element: cardElement, children: [], orphanable: true },
                                            twoWayLink({ element: featureListWrapper }, mapEditorNodes(editorNodes, options)),
                                        ]),
                                    ];
                                }
                                return [
                                    {
                                        element: card,
                                        children: [],
                                    },
                                ];
                            })), false));
                        })), false));
                    }
                    var _g = __read(splitAt(quoteElement), 2), sectionWrapper = _g[0], _h = __read(_g[1], 2), sectionHeader = _h[0], sectionBody = _h[1];
                    var tableHeader = sectionHeader;
                    var isSectionCollapsed = !sectionBody;
                    if (isSectionCollapsed) {
                        return twoWayLink({ element: sectionWrapper }, [{ element: tableHeader, children: [] }]);
                    }
                    var _j = __read(splitAt(sectionBody, "tbody"), 2), tableWrapper = _j[0], tableRows = _j[1];
                    return twoWayLink({ element: sectionWrapper }, [
                        { element: tableHeader, children: [], orphanable: true },
                        twoWayLink({ element: tableWrapper }, tableRows.map(function (row) {
                            var numberOfCols = row.querySelectorAll("td").length;
                            var children = [];
                            var wrapper = row;
                            for (var i = 0; i < numberOfCols; i++) {
                                var _a = __read(splitAt(wrapper, "td:nth-child(".concat(i + 1, ")")), 2), newWrapper = _a[0], _b = __read(_a[1], 1), cellContent = _b[0];
                                if (i === 0) {
                                    var _c = __read(splitAt(cellContent, ".section__description-text"), 2), descriptionWrapper = _c[0], descriptionNodes = _c[1];
                                    children.push([
                                        twoWayLink({ element: descriptionWrapper }, mapEditorNodes(descriptionNodes, options)),
                                    ]);
                                }
                                else if (!cellContent) {
                                    children.push([{ element: document.createElement("span"), children: [] }]);
                                }
                                else {
                                    children.push([{ element: cellContent, children: [] }]);
                                }
                                wrapper = newWrapper;
                            }
                            return twoWayLink.apply(void 0, __spreadArray([{ element: wrapper }], __read(children), false));
                        })),
                    ]);
                })),
            ]);
        },
    }); },
};
var AcceptBlock = {
    isBlockType: function (element) { return element.classList.contains("accept-module"); },
    make: function (element, options) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            var _a, _b, _c, _d;
            var _e = __read(splitAt(element, ".columns"), 2), wrapper = _e[0], children = _e[1];
            var _f = __read(splitAt(children[0]), 2), textWrapper = _f[0], textElements = _f[1];
            var unfurlAcceptForm = children[1].querySelector('[data-unfurl-form-for-printing="true"]');
            if (unfurlAcceptForm) {
                (_a = children[1].querySelector(".multi-accepter-icons")) === null || _a === void 0 ? void 0 : _a.remove();
                (_b = children[1].querySelector(".secured-wrapper")) === null || _b === void 0 ? void 0 : _b.remove();
                var _g = __read(splitAt(children[1], ".accept-wrapper"), 2), acceptWrapper = _g[0], acceptChildren_1 = _g[1];
                var _h = __read(splitAt(acceptChildren_1[0], "ng-include"), 2), acceptButtonWrapper = _h[0], _j = __read(_h[1], 2), acceptButton = _j[0], multiAccepters = _j[1];
                var _k = __read(splitAt(multiAccepters), 2), multiAcceptersWrapper = _k[0], multiAccepterChildren = _k[1];
                var accepterCount = parseInt((_d = (_c = acceptWrapper.querySelector(".accept-wrapper")) === null || _c === void 0 ? void 0 : _c.getAttribute("data-accepter-count")) !== null && _d !== void 0 ? _d : "1", 10);
                return twoWayLink(__assign({ element: wrapper }, getColors(element)), __spreadArray(__spreadArray([], __read((0, CommonUtils_1.includeIf)(!options.isAgreementView, [
                    twoWayLink({ element: textWrapper }, mapEditorNodes(textElements, options)),
                ])), false), [
                    twoWayLink({ element: acceptWrapper }, __spreadArray([
                        twoWayLink({ element: acceptButtonWrapper }, [
                            twoWayLink({ element: multiAcceptersWrapper }, multiAccepterChildren.map(function (child) {
                                if (child.querySelector(".accepter-list")) {
                                    var _a = __read(splitAt(child, ".accepter-list"), 2), accepterListWrapper = _a[0], _b = __read(_a[1]), accepterListHeading = _b[0], accepters = _b.slice(1);
                                    return twoWayLink({ element: accepterListWrapper }, __spreadArray([
                                        { element: accepterListHeading, children: [], orphanable: true }
                                    ], __read(accepters.map(function (accepter) { return ({ element: accepter, children: [] }); })), false));
                                }
                                else {
                                    return { element: child, children: [] };
                                }
                            })),
                        ])
                    ], __read(Array.from(new Array(accepterCount)).map(function () {
                        var _a = __read(splitAt(acceptChildren_1[1], ".form-screen"), 2), formScreen = _a[0], formScreenElements = _a[1];
                        return twoWayLink({
                            element: formScreen,
                        }, __spreadArray([], __read(formScreenElements.map(function (element) {
                            if (element.classList.contains("form-v2__form")) {
                                var _a = __read(splitAt(element), 2), formWrapper = _a[0], formFields = _a[1];
                                return twoWayLink({ element: formWrapper }, formFields.map(function (formField) {
                                    if (formField.classList.contains("custom-details")) {
                                        var _a = __read(splitAt(formField), 2), customFormWrapper = _a[0], customFormFields = _a[1];
                                        return twoWayLink({ element: customFormWrapper }, customFormFields.map(function (field) { return ({
                                            element: field,
                                            children: [],
                                        }); }));
                                    }
                                    return {
                                        element: formField,
                                        children: [],
                                    };
                                }));
                            }
                            return { element: element, children: [] };
                        })), false));
                    })), false)),
                ], false));
            }
            else {
                var _l = __read(splitAt(children[1], "ng-include"), 2), acceptButtonWrapper = _l[0], _m = __read(_l[1], 2), acceptButton = _m[0], multiAccepters = _m[1];
                var _o = __read(splitAt(multiAccepters), 2), multiAcceptersWrapper = _o[0], multiAccepterChildren = _o[1];
                return twoWayLink(__assign({ element: wrapper }, getColors(element)), [
                    twoWayLink({ element: textWrapper }, mapEditorNodes(textElements, options)),
                    twoWayLink({ element: acceptButtonWrapper }, [
                        { element: acceptButton, children: [] },
                        twoWayLink({ element: multiAcceptersWrapper }, multiAccepterChildren.map(function (child) {
                            if (child.querySelector(".accepter-list")) {
                                var _a = __read(splitAt(child, ".accepter-list"), 2), accepterListWrapper = _a[0], _b = __read(_a[1]), accepterListHeading = _b[0], accepters = _b.slice(1);
                                return twoWayLink({ element: accepterListWrapper }, __spreadArray([
                                    { element: accepterListHeading, children: [], orphanable: true }
                                ], __read(accepters.map(function (accepter) { return ({ element: accepter, children: [] }); })), false));
                            }
                            else {
                                return { element: child, children: [] };
                            }
                        })),
                    ]),
                ]);
            }
        },
    }); },
};
var VideoBlock = {
    isBlockType: function (element) { return element.classList.contains("video-module"); },
    make: function (element, options) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            var _a = __read(splitAt(element, ".row"), 2), wrapper = _a[0], _b = __read(_a[1], 2), header = _b[0], video = _b[1];
            var _c = __read(splitAt(header), 2), headerWrapper = _c[0], headerChildren = _c[1];
            var headerContentNodes = mapEditorNodes(headerChildren, options);
            var headerOrphanable = headerContentNodes.length === 0 || headerContentNodes[headerContentNodes.length - 1].orphanable;
            return twoWayLink(__assign({ element: wrapper }, getColors(element)), [
                twoWayLink({ element: headerWrapper, orphanable: headerOrphanable }, headerContentNodes),
                { element: video, children: [] },
            ]);
        },
    }); },
};
var EmbedBlock = {
    isBlockType: function (element) { return element.classList.contains("source-module"); },
    make: function (element) { return ({
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            return __assign(__assign({ element: element.cloneNode(true) }, getColors(element)), { children: [] });
        },
    }); },
};
var AuditTrail = {
    isBlockType: function (element) { return element.classList.contains("audit-trail"); },
    make: function (element) { return ({
        startNewPageBefore: true,
        blockId: element.getAttribute("block-id"),
        type: element.getAttribute("data-type"),
        get tree() {
            var _a = __read(splitAt(element), 2), wrapper = _a[0], _b = __read(_a[1], 3), heading = _b[0], projectDetails = _b[1], accepterDetails = _b[2];
            var _c = __read(splitAt(projectDetails), 2), projectDetailsWrapper = _c[0], projectDetailsContent = _c[1];
            var _d = __read(splitAt(accepterDetails, ".content"), 2), accepterDetailsWrapper = _d[0], accepterDetailsContent = _d[1];
            var mapDetails = function (elements) {
                return elements.map(function (el) {
                    if (el.tagName === "H2") {
                        return { element: el.cloneNode(true), children: [], orphanable: true };
                    }
                    if (el.tagName === "TABLE") {
                        var _a = __read(splitAt(el, "tbody"), 2), tableWrapper = _a[0], tableRows = _a[1];
                        return twoWayLink({ element: tableWrapper }, tableRows.map(function (row, rowIndex) { return ({ element: row, children: [], orphanable: rowIndex < 3 }); }));
                    }
                    throw new Error("Got unsupported html element in Audit Trail parsing");
                });
            };
            return twoWayLink(__assign({ element: wrapper }, getColors(element)), [
                { element: heading, children: [], orphanable: true },
                twoWayLink({ element: projectDetailsWrapper }, mapDetails(projectDetailsContent)),
                twoWayLink({ element: accepterDetailsWrapper }, mapDetails(accepterDetailsContent)),
            ]);
        },
    }); },
};
var getProjectBlockId = function (element) {
    var _a;
    return ((_a = element === null || element === void 0 ? void 0 : element.getAttribute("class").split(" ").find(function (cn) { return cn.startsWith("project-block--id-"); })) === null || _a === void 0 ? void 0 : _a.match(/project-block--id-(.*)/)[1]);
};
var AgreementBlock = {
    isBlockType: function (element) { return element.classList.contains("agreement-block"); },
    make: function (element, options) { return ({
        blockId: getProjectBlockId(element),
        type: "Agreement",
        get tree() {
            var _a = __read(splitAt(element, ".columns"), 2), wrapper = _a[0], children = _a[1];
            return twoWayLink(__assign({ element: wrapper }, getColors(element)), mapEditorNodes(children, options));
        },
    }); },
};
var blockTypes = [
    SplashBlock,
    TextBlock,
    QuoteBlock,
    ReactQuoteBlock,
    AcceptBlock,
    VideoBlock,
    EmbedBlock,
    AuditTrail,
    AgreementBlock,
];
var createBlocks = function (elements, options) {
    var e_2, _a;
    var blocks = [];
    if (options.isAgreementView) {
        var agreementBlock = elements.find(function (element) { return AgreementBlock.isBlockType(element); });
        var acceptBlock = elements.find(function (element) { return AcceptBlock.isBlockType(element); });
        if (agreementBlock && acceptBlock) {
            blocks.push(AgreementBlock.make(agreementBlock, options));
            blocks.push(AcceptBlock.make(acceptBlock, options));
        }
        return blocks;
    }
    var _loop_1 = function (element) {
        var BlockType = blockTypes.find(function (Type) { return Type.isBlockType(element); });
        if ((options.removeVideos && BlockType === VideoBlock) ||
            (options.removeEmbeddedContent && BlockType === EmbedBlock)) {
            return "continue";
        }
        if (BlockType) {
            blocks.push(BlockType.make(element, options));
        }
        else {
            console.warn("No block type for", element);
            blocks.push(GenericBlock.make(element, options));
        }
    };
    try {
        for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
            var element = elements_1_1.value;
            _loop_1(element);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return blocks;
};
exports.createBlocks = createBlocks;
